import React, { useEffect } from 'react'
import Layout from "../components/Layout"
import Seo from "../components/seo"
import _map from 'lodash/map'
import { PhotoAlbum } from 'react-photo-album'
import { graphql } from 'gatsby'
import {RTFJS} from 'rtf.js';
import { Modal } from 'react-bootstrap'
RTFJS.loggingEnabled(false);

function stringToArrayBuffer(string) {
  const buffer = new ArrayBuffer(string.length);
  const bufferView = new Uint8Array(buffer);
  for (let i = 0; i < string.length; i++) {
      bufferView[i] = string.charCodeAt(i);
  }
  return buffer;
}

const renderPhoto = ({
  layout,
  layoutOptions,
  imageProps: { alt, style, ...restImageProps }
}) => {
  console.log(restImageProps.title)
  return (
  <div
    style={{
      width: style?.width,
      padding: `${layoutOptions.padding - 2}px`,
      paddingBottom: 0
    }}
  >
    <img
      alt={alt}
      style={{ ...style, width: "100%", padding: 0 }}
      {...restImageProps} title={alt}
    />
    <div
      style={{
        paddingTop: "8px",
        paddingBottom: "8px",
        textAlign: "center",
        overflowWrap: "break-word"
      }}
    >
      {restImageProps.title.map ? restImageProps.title.map((e,i) => <div key={i} dangerouslySetInnerHTML={{__html: e.outerHTML}}/>) : ''}
    </div>
  </div>
)};

const ClientViewTemplate = (props) => {
  const { pageContext, data } = props
  const { client, category } = pageContext
  const [ clientData, setClientData ] = React.useState(null)
  const [ show, setShow ] = React.useState(false)
  const [ curBrand, setCurBrand ] = React.useState(null)


  useEffect(() => {
    const getDescription = async (file, allfiles) => {
      let otherFile = allfiles.nodes.filter(e => e.name === file.name && e.relativeDirectory === file.relativeDirectory && e.extension !== file.extension)[0]
      if (otherFile){
        return await getText(otherFile.internal.content)
      } else
        return ''
    }

    const isDescription = async (file, allfiles) => {
      return allfiles.nodes.filter(e => e.name === file.name && e.relativeDirectory === file.relativeDirectory && e.extension !== file.extension)[0]
    }
  
    const getImageWidth = (file) => {
      return file.childrenImageSharp[0] ? file.childrenImageSharp[0].original.width : 500
    }
  
    const getImageHeight = (file) => {
      return file.childrenImageSharp[0] ? file.childrenImageSharp[0].original.height : 500
    }
  
    const getText = async (text) => {
      const doc = new RTFJS.Document(stringToArrayBuffer(text));
      const htmlElement = await doc.render()
      return htmlElement
    }
  
    async function getData() {
    const root = { brands: {}, photos: [], logos: [], text: [] }
    const rootDir = `clients/${client}`
    console.log(data.allFile)
    for (let i = 0; i < data.allFile.nodes.length; i++) {
      const file = data.allFile.nodes[i]
      if (file.relativeDirectory === rootDir) {
        if (file.internal.content != null) {
          if (!isDescription(file, data.allFile))
            root.text.push(await getText(file.internal.content))
        } else
            root.photos.push({src: file.publicURL, width: getImageWidth(file), 
              height: getImageHeight(file), title: await getDescription(file, data.allFile)})
      } else if (file.relativeDirectory && file.relativeDirectory.startsWith(rootDir + '/')) {
        const relativePath = file.relativeDirectory.substr(rootDir.length + 1).split("/")
        if (relativePath[0].toLowerCase() === 'logo') {
          root.logos.push(file.publicURL)
        } else if (category == null || category === relativePath[0]) {
          if (relativePath.length === 1) {
            if (file.internal.content != null) {
              if (!isDescription(file, data.allFile))
                root.text.push(await getText(file.internal.content))
            } else
              root.photos.push({src: file.publicURL, width: getImageWidth(file),
                height: getImageHeight(file), title: await getDescription(file, data.allFile)})
          } else if (relativePath.length > 1) {
            if (relativePath[1].toLowerCase() !== 'local' && !root.brands[relativePath[1]]) {
              root.brands[relativePath[1]] = {photos: [], logos: [], text: []}
            }
            if (relativePath.length === 2) {
              if (relativePath[1].toLowerCase() === 'local') {
                if (file.internal.content != null) {
                  if (!isDescription(file, data.allFile))
                    root.text.push(await getText(file.internal.content))
                } else
                  root.photos.push({src: file.publicURL, width: getImageWidth(file),
                    height: getImageHeight(file), title: await getDescription(file, data.allFile)})
              } else {
                if (file.internal.content != null) {
                  if (!isDescription(file, data.allFile))
                    root.brands[relativePath[1]].text.push(await getText(file.internal.content))
                } else {
                  root.brands[relativePath[1]].photos.push({src: file.publicURL, width: getImageWidth(file),
                    height: getImageHeight(file), title: await getDescription(file, data.allFile)})
                }
              }
            }
            else {
              if (relativePath[1].toLowerCase() !== 'local')
                root.brands[relativePath[1]].logos.push(file.publicURL)
              else
                root.logos.push(file.publicURL)
            }
          }
        }
      }
      
    }
    setClientData(root)
  }
  if (data && client)
    getData()
  }, [data, client, category])
  
  return (
    <Layout>
    <Seo title={client} />
    <h1 className="has-text-centered title is-1 has-text-info">{client}</h1>   
    <div className="columns is-centered is-multiline">
      {clientData?.text && clientData.text.map((text, i) => 
        <div key={i} className='column is-3'>{text.map((e,i) => <div key={i} dangerouslySetInnerHTML={{__html: e.outerHTML}}/>)}</div>
      )}
        {_map(clientData?.logos, (logo, key) => (
          <div key={key} className="column is-4">
            {<img src={logo} alt={client} />}
          </div>
        ))}
    </div>
    {clientData?.brands && <h1 className='title m-4'>Brands & Products</h1>}
    <div className="columns is-centered is-multiline" style={{ alignItems: 'flex-end'}}>
      {_map(clientData?.brands, (data, brand) => (
        <div key={brand} className="column is-3" style={{maxWeidt: '30%'}}>
          <div style={{textAlign: 'center', cursor: 'pointer'}} onClick={async () => {
            setCurBrand(brand)
            setShow(true);
          }}>
          {data.logos && data.logos.length > 0 && <img src={data.logos[0]} alt={brand} />}
          <span>{brand}</span>
          </div>

        </div>
      ))}
    </div>
    <PhotoAlbum
      layout="rows"
      photos={clientData?.photos}
      renderPhoto={renderPhoto}
    />
    <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{curBrand}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="columns is-centered is-multiline">
          {clientData?.brands[curBrand]?.text.map((text, i) => 
            <div key={i} className='column is-3'>{text.map((e,i) => <div key={i} dangerouslySetInnerHTML={{__html: e.outerHTML}}/>)}</div>
          )}
            {_map(clientData?.brands[curBrand]?.logos, (logo, key) => (
              <div key={key} className="column is-4">
                {<img src={logo} alt={client} />}
              </div>
            ))}
        </div>
        <PhotoAlbum
          layout="rows"
          photos={clientData?.brands[curBrand]?.photos}
          renderPhoto={renderPhoto}
        />
      </Modal.Body>
    </Modal>
  </Layout>
  )
}

export default ClientViewTemplate

export const pageQuery = graphql`
  query ClientData($clientregex: String!) {
    allFile(filter: {relativeDirectory: {regex: $clientregex}}) {
      nodes {
        name
        relativeDirectory
        extension
        publicURL
        internal {
          content
        }
        childrenImageSharp {
          original {
            height
            width
          }
        }
      }
    }
  }
`